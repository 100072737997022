@use "sass:color";

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Raleway", sans-serif;
  background-color: black;
}

#root {
  $accent-color: #f51313;
  --accent-color-dark: color.scale($accent-color, $lightness: -30%);
}

:root {
}
