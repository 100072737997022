.search {
  height: 100%;
  width: 100%;
  max-width: 100%;
  color: white;
  text-transform: uppercase;
  .pokemon-searchbar {
    background-color: rgba(255, 255, 255, 0.125);
    outline: none;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 100%;
    height: 9%;
    color: white;
    font-size: 1.3rem;
    padding-left: 1rem;
    &::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.779);
    }
  }
}

.searchPage{
  display: flex;
  flex-direction: column;
}
